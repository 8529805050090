export const TXN_URL = "0a2636f1-6c66-44a7-b9e2-5ed151b79a85";

/** DEV URL - API'S | S3 (FOR UPLOADS) */
/**  Old dev URL */
// export const BASE_URL = "http://35.171.104.106:8501";
// export const FILE_UPLOAD_URL = "https://msb-uploads.s3.amazonaws.com/";
// export const UI_BASE_URL = "http://35.171.104.106/msb/#";

/**  New  dev URL */
// export const BASE_URL = "http://65.0.159.231:8501";
// export const FILE_UPLOAD_URL =
//   "https://msb-dev-uploads.s3.ap-south-1.amazonaws.com/";
// export const UI_BASE_URL = "http://65.0.159.231/msb/#";

/** UAT URL - API'S  | S3 (FOR UPLOADS) */
export const BASE_URL = "http://uat.iamsamarth.com:8501";
export const FILE_UPLOAD_URL =
  "https://msb-uat-uploads.s3.ap-south-1.amazonaws.com/";
export const UI_BASE_URL = "http://uat.iamsamarth.com/#";

/** PROD URL - API'S  | S3 (FOR UPLOADS) */
// export const BASE_URL = "https://api.iamsamarth.com";
// export const FILE_UPLOAD_URL = "https://msb-prod-uploads.s3.amazonaws.com/";
// export const UI_BASE_URL = "https://app.iamsamarth.com/#";

/** PROD URL (TESTING) - API'S  | S3 (FOR UPLOADS) */
// export const BASE_URL = "http://3.6.92.95:8502";

/** PROD URL (OLD) - API'S  | S3 (FOR UPLOADS) */
// export const BASE_URL = 'https://app.iamsamarth.com:8502';

/** S3 pre-production URL */
// export const BASE_URL = "http://3.108.49.94:8502";
// export const FILE_UPLOAD_URL = "https://msb-pre-prod-uploads.s3.amazonaws.com/";
// export const UI_BASE_URL = "http://3.108.49.94:8502/msb/#";
/**
 * create application config
 * > set base api url's
 * > set file uploads url (S3 purpose)
 * > razorpay apikey
 */

/* payment modes */
//'rzp_test_xg07ACCJYc3T4Y'  //prod => rzp_live_2PexEkdew4nlrX

const appConfig = {
  FILE_UPLOAD_URL: FILE_UPLOAD_URL,
  // BASE_URL: process.env.REACT_APP_URL
  BASE_URL: BASE_URL,

  // test mode
  // paymentKey: "rzp_test_xg07ACCJYc3T4Y",

  //production mode
  paymentKey: "rzp_live_2PexEkdew4nlrX",

  // test url
  // ccavenue_url: "https://test.ccavenue.com",

  // test prod
  ccavenue_url: "https://secure.ccavenue.com",

  CCAVENUE_ACCESS_CODE: "AVQU84KG68AH72UQHA",

  TXN_URL: TXN_URL,
};
/** export default app config */
export default appConfig;
